define('modules/navigation/basic/js/base.js',[
    "app/config", "jquery", "app/util", "app/redirect", "wow"
], function (config, $, util, redirect) {
    return {
        name: 'navigationbasic',
        selectors: false,
        remoteData: [],
        extendable: true,

        lastRoute: null, // Null defaults to home

        routes: [
            {
                route: '.*',
                fn: function (hash) {
                    return this.navigate(hash);
                },
                priority: 10
            }, {
                route: '.*',
                fn: 'trackRoutes',
                extra: true
            }, {
                route: '.*',
                fn: 'homepageBgElements',
                extra: true
            }, {
                route: 'top',
                fn: function () {
                    this.navigate('home');
                    util.scrollBodyToTop(500, true);
                },
                priority: 5
            }
        ],

        // TODO - Add our animation options
        options: {
            animationType: false,
            smoothNavigate: false
        },

        initialize: function () {
            if (this.animationType) {
                this.initAnimation();
            }
        },

        initAnimation: $.noop, // TODO - Use our animations

        navigate: function (hash) {
            //>>excludeStart("production",pragmas.production);
            console.debug('Basic navigation module trigger: ', hash);
            //>>excludeEnd("production");
            hash = this.setDefaultRoute(hash);
            if (hash) {
                var pages = this.getNewPage(hash);
                if (pages) {
                    this.switchPages(pages);
                } else {
                    return false;
                }
            }
        },

        setDefaultRoute: function (hash) {
            // Set the default starting route
            if ((!hash && this.lastRoute === null) || (hash.length < 1)) {
                return 'home';
            } else if (this.lastRoute == hash) {
                return false;
            }
            return hash;
        },

        getNewPage: function (hash) {
            // Get our pages
            var $currentPage = $('.pageContainer[data-route="' + this.lastRoute + '"]'),
                $newPage = $('.pageContainer[data-route="' + hash.split('/')[0] + '"], .contentRedirect[data-route="' + hash.split('/')[0] + '"], *[data-route-section][data-route="' + hash.split('/')[0] + '"]');
            if (!$newPage || $newPage.length === 0) {
                return false;
            } else if ($currentPage.is($newPage)) {
                return [];
            } else {
                if ($newPage.attr('data-route-section')) {
                    var sectionPage = $('.pageContainer[data-route="' + $newPage.attr('data-route-section') + '"]');
                    if (sectionPage.length > 0) {
                        return [$currentPage, sectionPage, $newPage];
                    } else {
                        return false;
                    }
                } else {
                    return [$currentPage, $newPage];
                }
            }
        },

        switchPages: function (pages) {

            if (pages.length > 0) {
                var currentPage = pages[0],
                    newPage = pages[1].first(),
                    relayPage = pages[2];
                if (currentPage && $(currentPage).length > 0 && $(currentPage).attr('data-route-trackscroll') != undefined) {
                    $(currentPage).attr('data-route-trackscroll', util.getScrollParent($(currentPage)).scrollTop());
                }
                if (newPage.attr('data-redirect-target')) {
                    if ($(newPage.attr('data-redirect-target')).parents('#mainMenu').length > 0) { //&& !$(newPage).parents('#mainMenu').hasClass('open')) {
                        $('body').trigger('updatedashboard', [
                            true,
                            newPage.clone(true, true).removeClass('hidden')
                        ]);
                        $('#centerMenuContent').addClass('sidebarPage');
                    } else {
                        redirect.target(newPage, $(newPage.attr('data-redirect-target')));
                    }
                } else {
                    $('.pageContainer').removeClass('active').hide();
                    $('.pageContainer .section').removeClass('active');
                    $(newPage).addClass('active').show().css('opacity', 1);
                    $('body').trigger('switchPage');
                    if (relayPage && $(relayPage).length > 0) {
                        $(relayPage).get(0).scrollIntoView();
                    } else {
                        if (typeof $(newPage).attr('data-route-trackscroll') != 'undefined') {
                            if (!$('body').hasClass('newsModalOpen')) {
                                util.getScrollParent($(newPage)).scrollTop($(newPage).attr('data-route-trackscroll'));
                            }

                        } else {
                            if (this.smoothNavigate) {
                                util.scrollBodyToTop();
                            } else {
                                util.scrollBodyToTop(0);
                            }
                        }
                    }
                }
            }

        },

        trackRoutes: function (hash) {
            this.lastRoute = hash;
        },

        homepageBgElements: function (hash) {
            if ($.inArray(hash, ['home', '']) >= 0) {
                // we want to init on home route and empty route - which opens the homepage
                setTimeout(function () {
                    var homeIntroWidth = $('.home-top-card-bg-el').width(),
                        homeIntroHeight = $('.home-top-card-bg-el').height();

                    $('.home-top-card-bg-el-left-inner').width(homeIntroWidth).height(homeIntroHeight);
                    $('.home-top-card-bg-el-right-inner').width(homeIntroWidth).height(homeIntroHeight);
                }, 400);
            }
        }
    }
});

