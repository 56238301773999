define('modules/finalists/sidebyside/js/base.js',["app/module", "app/config", "app/util", "jquery", "templates", "skrollr"], function (module, config, util, $, templates, skrollr) {
    return new module({
        name: 'judges',
        selectors: '.finalistContainer',
        remoteData: [config.urls.winners],
        routes: [{
            route: 'winners/(\\d+)',
            fn: function (hash) {
                $('.finalistContainer .winner-wrap-inner .btn[data-winner="' + hash.split('/')[1] + '"]').trigger('click');
            },
            extra: true,
            delay: 2500
        }
        , {
            route: 'winners',
            // fn: 'showLeftSide',
            fn: function(hash) {
                // console.log('test winners function');
            },
            extra: true,
            delay: 500
        }
        ],

        config: {
            rightPanelLayout: true,
            showTypeBar: false
        },

        methods: {
            
            initialize: function (data) {

                this.data = data;

                $('.finalistContainer').append(templates.finalistspage({
                    winner: data
                }));

                var modalElement = $('.winner-details-modal');
                modalElement.html('');

                if (modalElement.hasClass('shown')) {
                    modalElement.removeClass('shown');
                    $('body').removeClass('locked');
                };
    
                // Add the click handlers to judgeboxes
                $('.finalistContainer .winner-wrap-inner .btn').on('click', $.proxy(this.onFinalistBoxClick, this));

                // click on closing element
                $('.winner-details-modal').on('click', '.js-modal-close', $.proxy(function (e) {
                    e.preventDefault();

                    var displayHashInitial = window.location.href.split('/')[0] + '/#winners';
                    history.pushState(null, null, displayHashInitial);

                    var winnerDetailsModal = $('.winner-details-modal');

                    winnerDetailsModal.find('.finalistVideo')[0].contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');

                    winnerDetailsModal.removeClass('shown');
                    $('body').removeClass('locked');
                }, this));
            },
    
            // Handle for when a judge is clicked on, this can also be manually triggered
            onFinalistBoxClick: function (e) {
    
                e.preventDefault();

                var modalElement = $('.winner-details-modal');
                modalElement.html('');
    
                var winnerId = $(e.delegateTarget).attr('data-winner');

                var displayHash = '#winners/' + winnerId;

                history.pushState(null, null, displayHash);

                var chosenWinnerDetails = this.data.filter(function(item) {
                    return item.id == winnerId;
                });

                if (Array.isArray(chosenWinnerDetails)) {
                    chosenWinnerDetails = chosenWinnerDetails[0]
                }

                modalElement.append(templates.finalistOverlay({
                    winnerDetails: chosenWinnerDetails
                }));

                modalElement.addClass('shown');
                $('body').addClass('locked');
    
                return false;
    
            }
        }
    });
});

