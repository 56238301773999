define('modules/judgespreview/basic/js/base.js',["app/config","app/util","jquery","templates","app/module","bootstrap/carousel","jquery.jcarousel"], function(config,util,$,templates,module) {
    return new module({
        name: 'judgespreview',
        selectors: '.judgesPreviewWrap',
        remoteData: [config.urls.judges],

        routes: [{
            route: 'home',
            fn: 'refreshJudgesPreview',
            extra: true,
            delay: 2500
        }],

        initialize: function(data) {
            this.renderTemplates(data);
        },

        onRender: function() {
            this.refreshJudgesPreview();
            this.handleJudgeNavigation();
            this.initCarousels();
        },

        methods: {
            renderTemplates: function(data) {

                //sort data first
                data.sort(function(a, b) {
                    return a.id - b.id;
                });

                $(this.getSelectors()).html(templates.judgespreview($.grep(data,function(record){
                    return (record['quote'] && record['quote'].length > 1);
                })));

                $(this.getSelectors()).find('.meetJudgeBtn').hover(function(){
                  $(this).parents('.activeContainer').toggleClass('hovered');
                });

            },
            initCarousels: function () {
                var self = $(this.getSelectors()),
                    carousel = self.find('.carousel');

                carousel.carousel({
                    interval: false,
                    wrap: true
                }).on('touchstart', function (event) {
                    var xClick = event.originalEvent.touches[0].pageX;
                    $(this).one('touchmove', function (event) {
                        var xMove = event.originalEvent.touches[0].pageX;
                        if (Math.floor(xClick - xMove) > 5) {
                            $(this).carousel('next');
                        }
                        else if (Math.floor(xClick - xMove) < -5) {
                            $(this).carousel('prev');
                        }
                    });
                    carousel.on('touchend', function () {
                        $(this).off('touchmove');
                    });
                });

                self.find('.thumbSlider').jcarousel({
                    wrap: 'circular',
                    center: false
                });
            },
            refreshJudgesPreview: function() {
                // TODO - If we need a resize handler or routing refresh, let's add it here
            },
            handleJudgeNavigation: function() {
                $(this.getSelectors()).find('.judgebox').on('click',$.proxy(function(e){
                    e.preventDefault();
                    var judgeId = $(e.currentTarget).attr('data-judgeid'),
                        slide = $(this.getSelectors()).find('.carousel .item[data-judgeid="'+judgeId+'"]');
                    $(this.getSelectors()).find('.carousel').carousel($(slide).index()); // This relays to the below event listener
                },this));

                $(this.getSelectors()).find('.carousel').on('slide.bs.carousel',$.proxy(function(e){

                    var judgeId = $(e.relatedTarget).attr('data-judgeid'),
                        judgeBox = $(this.getSelectors()).find('.judgebox[data-judgeid="'+judgeId+'"]');
                    $(this.getSelectors()).find('.thumbSlider').jcarousel('scroll',$(judgeBox));
                    $(this.getSelectors()).find('.judgebox').removeClass('active');
                    $(judgeBox).addClass('active');
                },this));

                $(this.getSelectors()).find('.carousel').on('slid.bs.carousel',$.proxy(function(e){
                    var activeJudge = $(this.getSelectors()).find('.carousel .item.active').index();
                    $(this.getSelectors()).find('.judgePreviewCount .current').html(("0" + (activeJudge + 1)).slice(-2));
                },this));
            }
        }
    });
});

