define('modules/sidebar/js/base.js',["app/config","jquery","app/util","templates"], function(config,$,util,templates) {
    return {
        name: 'sidebar',
        selectors: '#sidebar',
        remoteData: [config.urls.sidebar],

        routes: [{
            route: '.*',
            fn: 'updateBackgroundText',
            extra: true,
            delay: 150
        }],

        initialize: function (data) {

            // Load up the template
            $('#sidebar').append(templates.sidebar({
                menuItems: $.grep(data,function(rec){
                    return rec.megamenu; // Only include items set to display in megamenu
                }),
                config: config // TODO - Filter this down to selected keys
            }));
            
            this.attachEventHandlers();
            config.refresh();
            this.updateBackgroundText();
        },

        attachEventHandlers: function(){
            // User clicks a link in the sidebar
            $('.sidebar-item').click(function(event) {
                // If the sidebar has a tier1 menu...
                if ( $('.sidebar-tier1', $(this) ).length ) {
                    // Disable href action
                    event.preventDefault();
                    // Toggle the item's 'selected' class
                    $(this).toggleClass('sidebar-item-selected');
                }
				$('.daysToRegister a.navreg').show();
				$('.daysToRegister a.modal-close').hide();
            });

            // Sidebar inner (nested) menus
            $('#sidebar ul').click(function(event){
                event.stopPropagation(); // Stop propagation on inner links
            });

            $('.sidebar-inner-back').click(function(event) {
                setTimeout($.proxy(function(){
                    $(this).parent('.sidebar-inner-list').addClass('inner-closed');
                    $(this).parents('.sidebar-item-selected').height('auto');
                },this),1000);
                $(this).parents('.sidebar-item').css('right',parseInt($(this).closest('.sidebar-item')[0].style.right || 0, 10)-100+'%');
                event.stopPropagation();
            });
            $('.sidebar-inner-link').click(function(event) {
                $(this).children('.sidebar-inner-list').removeClass('inner-closed');
                $(this).parents('.sidebar-item').css('right',parseInt($(this).closest('.sidebar-item')[0].style.right || 0, 10)+100+'%');
                $(this).parents('.sidebar-item-selected').height($(this).children('.sidebar-inner-list').height());
                event.stopPropagation();
            });

            var $backgroundTextContainer =  $('#sideMenu .menu-text-large');
            this.backgroundText = "";
            this.backgroundImage = "";
            $('#sidebar')
                .on('mouseenter', '.sidebar-item', $.proxy(function(event){
                    var $sidebarItem = $(event.target);
                    if(!$sidebarItem.hasClass('sidebaar-item')){
                        $sidebarItem = $sidebarItem.parents('.sidebar-item');
                    }
                    var newBackgroundText = $sidebarItem.data('backgroundtext');
                    var newBackgroundImage = $sidebarItem.data('backgroundimage');

                    this.backgroundText = $backgroundTextContainer.text();
                    this.backgroundImage = $backgroundTextContainer.css('background-image');

                    if(newBackgroundText){
                        
                        $backgroundTextContainer.text(newBackgroundText);
                    }

                    if(newBackgroundImage){

                        $backgroundTextContainer.css('background-image', 'url("'+newBackgroundImage+'")');
                    }
                }, this))
                .on('mouseleave', '.sidebar-item', $.proxy(function(event){
                    $backgroundTextContainer.text(this.backgroundText);
                    $backgroundTextContainer.css('background-image', 'url("'+this.backgroundImage+'")');
                },this))
        },

        updateBackgroundText: function(){
            var mainRoute = window.location.hash.substring(1).split('/')[0];
            
            // ignore silent routes in order to preserve the current background text
            var ignoreRoutes = ['register', 'login', 'forgot', 'reset'];
            if(ignoreRoutes.indexOf(mainRoute) > -1){
                return;
            }

            if(mainRoute == ''){
                mainRoute = 'home';
            }

            $('#sidebar .sidebar-item-selected').removeClass('sidebar-item-selected');
            var $sidebarRootLinkItem = $('#sidebar .sidebar-item[href="#' + mainRoute +'"]');

            if($sidebarRootLinkItem.length == 0){
                $sidebarRootLinkItem = $('#sidebar .sidebar-item .sidebar-tier1 a[href="#' + mainRoute +'"]').parents('.sidebar-item');
            }

            this.backgroundText = "menu";
            if($sidebarRootLinkItem.length == 1){
                $sidebarRootLinkItem.addClass("sidebar-item-selected");
                this.backgroundText = $sidebarRootLinkItem.data("backgroundtext") || this.backgroundText;
            }
            
            $("#sideMenu .menu-text-large").text(this.backgroundText);
        }
    }
});
