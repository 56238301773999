define('hooks',['jquery', 'app/config', 'app/util', 'app/event-bus', 'templates', 'jquery.livequery'], function ($, config, util, eventBus, templates) {
    var methods = {
        onDocumentReady: function () {
            //>>excludeStart("production",pragmas.production);
            console.debug('Project hook - document ready.');
            //>>excludeEnd("production");
        },
        onAppInitialized: function () {
            //>>excludeStart("production",pragmas.production);
            console.debug('Project hook - app initialized.');
            //>>excludeEnd("production");

            // retire modal control
            $('body').on('click', '.retire-modal-trigger', function (e) {
                e.preventDefault();
                $('.retire-modal').addClass('visible');
                $('body').addClass('modal-visible');
            });

            $('.close-retire-modal').on('click', function () {
                $('.retire-modal').removeClass('visible');
                $('body').removeClass('modal-visible');
            });

            $(document).on('click', '.navbar-brand.scrollToTop', function (e) {
                e.preventDefault();

                $('html,body').animate({
                    scrollTop: 0
                }, 1000);
                $(this).unbind('click');
            });

            $('.navbar-share-toggle').on('mouseenter', function () {
                if (!Modernizr.touch) {
                    var self = $(this);
                    self.addClass('expanded');
                    self.siblings('.social-links-inner').addClass('expanded');
                }
            }).on('click', function () {
                var self = $(this);
                self.siblings('.social-links-inner').toggleClass('expanded');
            });

            $('.social-links-wrap').on('mouseleave', function () {
                if (!Modernizr.touch) {
                    var self = $(this);
                    $('.navbar-share-toggle').removeClass('expanded');
                    self.find('.social-links-inner').removeClass('expanded');
                }
            });

            $(document).on('click', '.download-dropdown-handle', function () {
                var self = $(this);

                self.closest('.download-dropdown').toggleClass('dropdown-opened');
                self.siblings('.download-dropdown-list').slideToggle(300);
            });

            // array.find ie support
            if (typeof Array.prototype.find === 'undefined') {

                Array.prototype.find = function (callback, thisArg) {
                    for (var i = 0; i < this.length; i++) {
                        if (callback.call(thisArg || window, this[i], i, this))
                            return this[i];
                    }
                    return undefined;
                };
            }

            $('body').livequery('.homePageContainer.active', $.proxy(function () {
                $(window).on('mousewheel DOMMouseScroll onmousewheel touchmove scroll', $.throttle(10, util.minScrollSpeed($.proxy(function (event) {

                    // TODO maybe create specific classes for these targets
                    // working for now

                    var processInView = util.isElInView($('.process-slidecard-wrap'), false);
                    var faqInView = util.isElInView($('.faqWrap'), false);
                    var lastCardInView = util.isElInView($('.lastCard.home'), false);

                    if (processInView) {
                        $('.home-side-nav').removeClass('home-side-nav--light');
                        $('.home-side-nav__list__item').removeClass('active');
                        $('.home-side-nav__list__item--process').addClass('active');
                    }
                    else if (faqInView) {
                        $('.home-side-nav').removeClass('home-side-nav--light');
                        $('.home-side-nav__list__item').removeClass('active');
                        $('.home-side-nav__list__item--faq').addClass('active');
                    }
                    else if (lastCardInView) {
                        $('.home-side-nav').addClass('home-side-nav--light');
                        $('.home-side-nav__list__item').removeClass('active');
                        $('.home-side-nav__list__item--lastCard').addClass('active');
                    }

                    $(document).on({
                        mouseenter: function () {
                            //stuff to do on mouse enter
                            $(this).addClass('toggle-in');
                            $('.home-side-nav__list__item.active').addClass('toggle-out');
                        },
                        mouseleave: function () {
                            //stuff to do on mouse leave
                            $(this).removeClass('toggle-in');
                            $('.home-side-nav__list__item.active').removeClass('toggle-out');
                        }
                    }, '.home-side-nav__list__item:not(.active)');

                }, this))));
            }, this)).livequery('.js-start-reading', function () {
                $(this).click(function (e) {
                    e.preventDefault();
                    if ($(this).attr('data-learnmore-scrollto').length > 0) {
                        $('.modal-news .inner-scrollable').animate({
                            scrollTop: 600
                        }, 1000);
                    }
                });
            });

            $(document).on('mousemove', '.newsPage .left.carousel-control', function (e) {
                $('.prev-tail').css({
                    left: e.pageX + 40,
                    top: e.pageY
                });
            }).on('mousemove', '.newsPage .right.carousel-control', function (e) {
                $('.next-tail').css({
                    right: ($(window).width() - e.pageX) + 20,
                    top: e.pageY
                });
            });

            // this controls the winners announcement banner on the homepage
            var initialHash = window.location.hash;

            if (initialHash === '#home' || initialHash === '') {
                $('.overlay-winners').addClass('shown');
                $('body').addClass('locked');
            };

            // This controls the winners overlay
            $('.overlay-winners .js-overlay-close').on('click', function(e) {
                var $self = $(this);

                if ($self.hasClass('btn')) {
                    setTimeout(function() {
                        $('.overlay-winners').removeClass('shown');
                        $('body').removeClass('locked');
                    }, 400);
                } else {
                    $('.overlay-winners').removeClass('shown');
                    $('body').removeClass('locked');
                };
                
            });

            /*
             * Resizes topcards on mobile/tablets to 100% of viewport height instead of 100vh
             */
            (function () {
                function doResize() {
                    if (win.width() <= 1024) {
                        topcards.css('height', html.height());
                    } else {
                        topcards.removeAttr('style');
                    }
                }

                function wait() {
                    setTimeout(function () {
                        if ($('#newsPromoGridCarousel').length === 0) {
                            wait();
                        } else {
                            topcards = $('#newsPromoGridCarousel, .mobileNewsDetailTopCard');
                            win.trigger('resize');
                            topcards = $('.home-top-card,.top-card,#newsPromoGridCarousel,.mobileNewsDetailTopCard');
                        }
                    }, 500);
                }

                var win = $(window),
                    html = $('html'),
                    topcards = $('.home-top-card,.top-card');
                win.on('resize', doResize);
                win.on('resizeWait', wait);
                wait();
            })();
        }
    };
    $(document).ready(methods.onDocumentReady);

    return methods;
});

