define('modules/timeline/vertical/js/base.js',[
  "app/module",
  "app/config",
  "jquery",
  "app/util",
  "templates",
  "moment",
  "skrollr",
  "bootstrap/tooltip",
  "bootstrap/carousel"
], function(module, config, $, util, templates, moment, skrollr) {
  return new module({
    name: 'timeline',
    selectors: '.timelineSection',
    remoteData: [(config.urls.timeline)],

    routes: [
      {
        route: '.*',
        fn: 'resetSkrollr',
        extra: true,
        delay: 50
      }
    ],
    // routes: [{
    //     route: 'timeline',
    //     fn: function() {
    //          Show the active tooltip
    //          TODO: do we really need the timeout here?
    //         setTimeout(function(){
    //             $('.timelinePoint.active').click();
    //     	}, 1000);
    //     },
    //     extra: true,
    //     delay: 2500
    // }],

    config: {
      // the exact dates will be used to precisely position the timeline events and the periods on the timeline
      timelineMode: "precise",
      // the timeline events will be placed on fixed intervals
      //timelineMode: "fixed",
      // used to set the actual height of the "timelineContainerInner"
      dayHeightInPx: 7,
      // sets the heigth in pixel of a single timeline event
      //eventHeightInPx: 300
      // set the height in pixels of the gap between the connecting lines
      connectingLinesGapInPx: 30,
      //selects timeline points as they go pass through a section with height of 100px and width 100% in the center of the screen
      selectPointsWithScroll: false,
      widthBreakpoint: 768,
      fixedMobileScroll: true
    },

    initialize: function(data) {
      this.$container = $(this.getSelectors());
      this.renderTemplate(data);
      this.attachEventHandlers();
      this.setInitialState();

      $(window).smartresize($.proxy(this.resetSkrollr, this));
      $(window).smartresize($.proxy(this.bindMobileFunctionality, this));
      $(window).smartresize($.proxy(this.bindNavigation, this));
      if (this.getConfig().selectPointsWithScroll) {
        $(window).smartresize($.proxy(this.selectPointsWithScroll, this));
      }
    },

    onRender: function() {
      util.setVisibleInterval(this.$container.find('.timelineContainer'), $.proxy(this.refreshTimeline, this), 300);

      setTimeout($.proxy(this.resetSkrollr, this), 3000)
      setTimeout($.proxy(this.bindMobileFunctionality, this), 3000);
      setTimeout($.proxy(this.bindNavigation, this), 3000);
      if (this.getConfig().selectPointsWithScroll) {
        setTimeout($.proxy(this.selectPointsWithScroll, this), 3000);
      }
    },

    methods: {
      renderTemplate: function(data) {
        $(data).each(function(index, record) {
          record.date = new Date(record.date);
        });
        this.data = data.sort(util.dynamicSort('date'));

        var viewModel = null;
        if (this.getConfig().timelineMode == "fixed") {
          viewModel = this.getFixedTimelineViewModel(data);
        } else if (this.getConfig().timelineMode == "precise") {
          viewModel = this.getPreciseTimelineViewModel(data);
        } else {
          // unexpected config value
          console.warn("Unknown Vertical timeline mode! ['" + this.getConfig().timelineMode + "']");
        }

        this.$container.append($(templates.timeline(viewModel)));
      },

      getFixedTimelineViewModel: function(data) {
        var timelinePeriods = []

        var now = new Date();
        var self = this;
        var isSelectedSet = false;
        var eventHeightInPx = this.getConfig().eventHeightInPx;
        this.totalPeriodsHeightInPx = data.length * eventHeightInPx;

        $.each(data, function(index, item) {
          // updating events
          item.offsetPercentage = index / data.length * 100;
          item.sizePx = eventHeightInPx - self.getConfig().connectingLinesGapInPx;

          if (!isSelectedSet && now < item.date) {
            item.selected = true;
            isSelectedSet = true;
          }

          // extracting periods
          var period = timelinePeriods.find(function(p) {
            return p.name === item.period
          })
          if (!period) {
            var tokens = item.periodlength.split("-");
            var originalEndDate = new Date(tokens[1]);
            period = {
              name: item.period,
              eventsCount: 0,
              startDate: new moment(tokens[0]),
              endDate: new moment(originalEndDate).add(1, "days").subtract(1, 'seconds')
            }

            timelinePeriods.push(period);
          }

          period.eventsCount += 1;
        })

        var eventsCountInPreviousPeriods = 0;
        $.each(timelinePeriods, function(index, period) {
          period.offsetPercentage = eventsCountInPreviousPeriods / data.length * 100;
          period.sizePercentage = period.eventsCount / data.length * 100;
          eventsCountInPreviousPeriods += period.eventsCount;

          if (period.startDate.month() == period.endDate.month()) {
            period.periodText = period.startDate.format("MMMM");
          } else {
            period.periodText = period.startDate.format("MMMM") + " - " + period.endDate.format("MMMM");
          }
        })

        if (!isSelectedSet) {
          data[data.length - 1].selected = true;
        }

        $.each(timelinePeriods, function(index, item) {
          item.offsetPercentage
        })

        return {
          timelinePoints: data, timelinePeriods: timelinePeriods,
          //currentMommentOffsetPercentage
        };
      },

      getPreciseTimelineViewModel: function(data) {
        var viewModel = {
          timelinePeriods: this.getPreciseTimelinePeriods(data)
        }

        var firstPoint = data[0];

        var now = new Date();
        var self = this;
        var isSelectedSet = false;
        var totalPeriodsHeightInPx = this.getConfig().dayHeightInPx * (this.timelineTotalPeriodLengthInMS / 86800000);
        var msToPercentageMultiplicator = 100 / this.timelineTotalPeriodLengthInMS;

        var previousTimelineItem = null;
        $.each(data, function(index, item) {
          item.offsetPercentage = (item.date - firstPoint.date) * msToPercentageMultiplicator;
          if (previousTimelineItem) {
            previousTimelineItem.sizePercentage = (item.date - previousTimelineItem.date) * msToPercentageMultiplicator;
            previousTimelineItem.sizePx = (totalPeriodsHeightInPx * previousTimelineItem.sizePercentage / 100) - self.getConfig().connectingLinesGapInPx;
          }

          previousTimelineItem = item;

          if (!isSelectedSet && index == data.length - 1) {
            item.selected = true;
          } else if (!isSelectedSet && now < item.date) {
            item.selected = true;
            isSelectedSet = true;
          }
        })

        this.totalPeriodsHeightInPx = totalPeriodsHeightInPx;
        viewModel.timelinePoints = data;
        viewModel.currentMommentOffsetPercentage = (now.getTime() - firstPoint.date) * msToPercentageMultiplicator;

        return viewModel;
      },

      getPreciseTimelinePeriods: function(data) {
        var timelinePeriods = [];
        $.each(data, function(index, item) {
          var period = timelinePeriods.find(function(p) {
            return p.name === item.period
          })
          if (!period) {
            var tokens = item.periodlength.split("-");
            var originalEndDate = new Date(tokens[1]);

            period = {
              name: item.period,
              startDate: new moment(tokens[0]),
              endDate: new moment(originalEndDate).add(1, "days").subtract(1, 'seconds')
            }

            timelinePeriods.push(period);
          }
        });

        this.timelineTotalPeriodLengthInMS = timelinePeriods[timelinePeriods.length - 1].endDate.valueOf() - timelinePeriods[0].startDate.valueOf();
        var msToPercentageMultiplicator = 100 / this.timelineTotalPeriodLengthInMS;

        var firstPeriod = timelinePeriods[0];
        $.each(timelinePeriods, function(index, period) {
          period.offsetPercentage = (period.startDate - firstPeriod.startDate) * msToPercentageMultiplicator;
          period.sizePercentage = (period.endDate - period.startDate) * msToPercentageMultiplicator;
          var periodText = "";
          if (period.startDate.month() == period.endDate.month()) {
            periodText = period.startDate.format("MMMM");
          } else {
            periodText = period.startDate.format("MMMM") + " - " + period.endDate.format("MMMM");
          }
          period.periodText = periodText;
        })

        return timelinePeriods;
      },

      refreshTimeline: function() {
        // Check the positioning of all points and push them apart to ensure no overlap
        var previousTimelinePoint = null;
        this.$container.find(".timelinePoint").each(function(index, currentPoint) {
          if (previousTimelinePoint) {
            var overlap = util.checkElementOverlap(previousTimelinePoint, currentPoint);
            if (overlap) {
              $(currentPoint).parents(".timelinePointWrapper").css('margin-top', '15px');

              var currentConnectLine = $(currentPoint).next('.timelineConnectLine'),
                currentConnectLineHeight = currentConnectLine.css('height');

              currentConnectLineHeight = parseFloat(currentConnectLineHeight, 10);

              currentConnectLineHeight = currentConnectLineHeight - 15;

              currentConnectLine.css('height', currentConnectLineHeight + 'px');
            };
          };

          previousTimelinePoint = currentPoint;
        });
      },

      setSkrollr: function() {
        skrollr.init({
          forceHeight: false, smoothScrollingDuration: 200, // 200
          mobileCheck: function() {
            return false;
          }
        });
      },

      destroySkrollr: function() {
        if (skrollr.get()) {
          skrollr.get().destroy();
        }
      },

      resetSkrollr: function() {
        //>>excludeStart("production",pragmas.production);
        console.debug('Performing skrollr animation refresh....');
        //>>excludeEnd("production");
        if ($(window).width() >= this.getConfig().widthBreakpoint && $('html').hasClass('no-touch')) {
          if (skrollr.get()) {
            skrollr.get().refresh();
          } else {
            this.setSkrollr();
          }
        } else {
          this.destroySkrollr();
        }
      },

      selectPointsWithScroll: function() {
        $(document).on('scroll', function() {
          var windowHeight = $(window).height();
          var upperLimit = windowHeight / 2 - 50;
          var lowerLimit = windowHeight / 2 + 50;
          var pointElements = $('.timelinePoint');

          //click every point as it goes through the middle of the screen (in a 100px height window, to avoid misses)
          $.each(pointElements, function(i, point) {
            var pointPositionInViewport = point.getBoundingClientRect().top;

            if (pointPositionInViewport > upperLimit && pointPositionInViewport < lowerLimit) {
              $(point).trigger('click');
            }
          });
        });
      },

      bindNavigation: function() {
        $(document).on('scroll', function() {
          var timelineContainer = $('.timelineScrollTarget');
          var navDesktop = $('.timelineNavigation');
          var topOfTimelineContainer = timelineContainer.offset().top
          var bottomOfTimelineContainer = topOfTimelineContainer + timelineContainer.outerHeight(true)
          var scrollTop = $(document).scrollTop();

          if (scrollTop < topOfTimelineContainer && !navDesktop.hasClass('timelineNavigation--hidden')) {
            navDesktop.addClass('timelineNavigation--hidden')
          }

          if (scrollTop > topOfTimelineContainer) {
            navDesktop.removeClass('timelineNavigation--hidden')
          }

          if (scrollTop > bottomOfTimelineContainer - $(window).innerHeight() && !navDesktop.hasClass('timelineNavigation--hidden')) {
            navDesktop.addClass('timelineNavigation--hidden')
          }

        });

      },

      bindMobileFunctionality: function() {
        if (!util.screen.xs()) {
          //reset in case it was previously modified by this function
          $(document).unbind('scroll');
          $('.timelinePointWrapper .timelineBoxWrapper').css({position: 'absolute'});
          return
        }

        if (this.getConfig().fixedMobileScroll) {
          $(document).on('scroll', function() {
            var windowHeight = $(window).height();
            var pointElements = $('.timelinePoint');
            var firstPointPosition = pointElements[0].getBoundingClientRect().top;
            var lastPointPosition = pointElements[pointElements.length - 1].getBoundingClientRect().top;

            //remove the fixed position from the selected timeline box to avoid it being pulled outside the timeline container
            //and simulate it snapping to the first/last timeline points
            var selectedBox = $('.timelinePointWrapper.selected .timelineBoxWrapper');
            if (windowHeight / 2 <= firstPointPosition || windowHeight / 2 >= lastPointPosition) {
              $(selectedBox).css({position: 'absolute'})
            } else {
              $(selectedBox).css({position: 'fixed'})
            }
          });
        }


      },

      attachEventHandlers: function() {
        this.$container.on("click", ".timelinePoint", $.proxy(function(event) {
          var $target = $(event.target);
          if (!$target.hasClass("timelinePoint")) {
            $target = $target.parents(".timelinePoint");
          }

          var timelinePointWrapper = $target.closest('.timelinePointWrapper');

          this.manageNavigationStates(timelinePointWrapper)

          // update "selected" class for timelinePointWrapper
          if (!timelinePointWrapper.hasClass('selected')) {
            this.$container.find(".timelinePointWrapper").removeClass("selected");
            this.$container.find('.timelineBox.selectedBox').removeClass('selectedBox').find('.btn-wrap').stop().slideUp(300);
            timelinePointWrapper.addClass('selected');
            timelinePointWrapper.find('.timelineBox').addClass('selectedBox').find('.btn-wrap').stop().slideDown(300);
          };

          // update the "active" class for the timelinePeriod
          var periodName = $target.parents(".timelinePointWrapper").data("periodname");
          this.$container.find(".timelinePeriod").removeClass("active");
          this.$container.find(".timelinePeriod[data-periodname='" + periodName + "']").addClass("active");

        }, this));

        this.$container.on('mouseenter', ".timelinePoint", function() {
          $(this).trigger('click');
        })

        this.$container.find(".timelineNavigationButton").on("click", $.proxy(function(event) {
          var $target = $(event.target),
            currentActivePoint = this.$container.find('.timelinePointWrapper.selected'),
            nextActivePoint,
            NextActivePointOffsetTop,
            NextActivePointHeight,
            windowHeight = $(window).height(),
            scrollDistance;

          if (!$target.hasClass("timelineNavigationButton")) {
            $target = $target.parents(".timelineNavigationButton");
          }

          if ($target.hasClass('prev')) {
            nextActivePoint = currentActivePoint.prev('.timelinePointWrapper');
          } else if ($target.hasClass('next')) {
            nextActivePoint = currentActivePoint.next('.timelinePointWrapper');
          };

          if (nextActivePoint.length) {
            nextActivePoint.find('.timelinePoint').trigger('click');
            NextActivePointOffsetTop = nextActivePoint.offset().top;
            NextActivePointHeight = nextActivePoint.height();
            scrollDistance = NextActivePointOffsetTop - ((windowHeight - NextActivePointHeight) / 2);
            this.manageNavigationStates(nextActivePoint)
            $('html, body').animate({
              scrollTop: scrollDistance
            }, 400);
          };
        }, this));
      },

      manageNavigationStates: function(activeTimelinePointWrapper) {
        activeTimelinePointWrapper.hasClass('first')
          ? this.$container.find('.timelineNavigationButton.prev').addClass('timelineNavigationButton--inactive')
          : this.$container.find('.timelineNavigationButton.prev').removeClass('timelineNavigationButton--inactive');

        activeTimelinePointWrapper.hasClass('last')
          ? this.$container.find('.timelineNavigationButton.next').addClass('timelineNavigationButton--inactive')
          : this.$container.find('.timelineNavigationButton.next').removeClass('timelineNavigationButton--inactive');
      },

      setInitialState: function() {
        this.$container.find(".timelinePoint.selected").click();

        this.$container.find(".timelineContainerInner").css("height", this.totalPeriodsHeightInPx);
      }
    }
  })
});

