define('modules/searchabledatabase/js/base.js',["app/module", "app/config", "jquery", "app/util", "templates", "app/features/infiniteloader", "bootstrap/dropdown", "bootstrap/select"], function (module, config, $, util, templates, infiniteloader) {
    return new module({
        name: 'searchabledatabase',
        selectors: '.searchableDatabase',
        remoteData: [config.urls.searchabledatabase],

        routes: [{
            route: 'explore-submissions',
            extra: true
        }],

        initialize: function (data) {
            this.data = (data || this.data);
            this.searchCount = 1;

            this.checkTerms(this.data);
        },

        methods: {

            checkTerms: function () {

                var module = this;
                $(this.getSelectors()).html('');
                if (parseInt($.cookie('sdb-agr'))) {
                    module.loadSearch();
                    module.performSearch();
                    module.unblockSearch();
                } else {
                    $(this.getSelectors()).html(templates.licenseagreement({
                        config: config
                    }));
                    module.loadSearch();
                    module.performSearch();
                    $(this.getSelectors()).find('.license-agreement-space').on('touchstart', function (e) {
                        if (e.touches.length > 1) {
                            return true;
                        } else {
                            return false;
                        }
                    });
                    $(this.getSelectors()).find('.license-agreement-confirm').click(function () {
                        var date = new Date();
                        date.setTime(date.getTime() + (30 * 24 * 60 * 60 * 1000)); // Expires in 30 days
                        $.cookie('sdb-agr', 1, {expires: date});
                        $('.license-agreement').slideUp('slow', function () {
                            module.unblockSearch();
                        });
                    });
                }
            },

            unblockSearch: function () {
                $('.search-container').removeClass('search-disabled');
                $('.results-container').removeClass('results-disabled');
            },

            loadSearch: function () {
                var module = this;
                $(this.getSelectors()).append(templates.searchabledatabasepage({
                    config: config
                }));

                $(this.getSelectors()).find('.selectpicker:not(.selectbox)').selectpicker({
                    liveSearch: true
                });

                $(this.getSelectors()).find('.selectpicker.selectbox').selectpicker({
                    liveSearch: false
                });

                module.initClickHandlers();
                module.initDropDownSearch();
            },

            initClickHandlers: function () {
                var module = this;

                $(this.getSelectors()).find('.searchButton').on('click', $.proxy(this.performSearch, this));

                $(this.getSelectors()).find('.resetButton').on('click', $.proxy(this.resetSearch, this));

                $(this.getSelectors()).find('.load-more-results').on('click', $.proxy(this.loadMore, this));
            },

            initDropDownSearch: function () {
                var module = this, searchType, searchTerm;

                // org name
                $(this.getSelectors()).find('.organizationName-select .bs-searchbox input').prop('placeholder', 'Search').keyup(function (e) {
                    // keycodes from 37 to 40 are used for the arrow keys
                    if (e.keyCode !== 37 && e.keyCode !== 38 && e.keyCode !== 39 && e.keyCode !== 40) {
                        searchTerm = this.value;
                        searchType = "organizationName";
                        if (this.value !== '') {
                            module.populateDropdowns(searchTerm, searchType);
                        }
                    }
                });

                module.populateDropdowns('', 'solutionCategory');

                // location
                $(this.getSelectors()).find('.location-select .bs-searchbox input').prop('placeholder', 'Search').keyup(function (e) {
                    // keycodes from 37 to 40 are used for the arrow keys
                    if (e.keyCode !== 37 && e.keyCode !== 38 && e.keyCode !== 39 && e.keyCode !== 40) {
                        searchTerm = this.value;
                        searchType = "location";
                        if (this.value !== '') {
                            module.populateDropdowns(searchTerm, searchType);
                        }
                    }
                });

                module.populateDropdowns('', 'solutionType');
            },

            populateDropdowns: function (searchTerm, searchType) {
                var solutionType = [
                    "Adaptation of an existing solution from one location to another",
                    "Expansion of an existing solution",
                    "New partnership to expand/scale",
                    "New, start-up venture"
                ];

                var solutionCategory = [
                    "Physical mobility service provider",
                    "Platform, application, product/service aggregator"
                ];

                var tempData = this.data;

                var orgOptions = [];

                if (searchTerm) {
                    var typedTerm = '<option value="' + searchTerm + '">' + searchTerm + '</option>';
                    orgOptions.push(typedTerm);
                }

                var tempOptions = [];

                if (searchTerm && searchType === 'organizationName') {

                    tempData.filter(element => {

                        if (element.OrganizationName.toLowerCase().includes(searchTerm)) {
                            tempOptions.push(element.OrganizationName);
                        }
                    });
                }

                if (searchTerm && searchType === 'location') {

                    tempData.filter(element => {
    
                        if (element.Location.toLowerCase().includes(searchTerm)) {
                            tempOptions.push(element.Location);
                        }
                    });
                }

                if (searchType === 'solutionCategory') {
                    var tempOrgOptions = solutionCategory;
                    for (var i = 0; i < tempOrgOptions.length; i++) {
                        var option = '<option value="' + tempOrgOptions[i] + '">' + tempOrgOptions[i] + '</option>';
                        orgOptions.push(option);
                    }
                };

                if (searchType === 'solutionType') {
                    var tempOrgOptions = solutionType;
                    for (var i = 0; i < tempOrgOptions.length; i++) {
                        var option = '<option value="' + tempOrgOptions[i] + '">' + tempOrgOptions[i] + '</option>';
                        orgOptions.push(option);
                    }
                };

                for (var i = 0; i < tempOptions.length; i++) {
                    var option = '<option value="' + tempOptions[i] + '">' + tempOptions[i] + '</option>';
                    orgOptions.push(option);
                }

                $(this.getSelectors()).find('.' + searchType + '-select .selectpicker').html(orgOptions);
                $(this.getSelectors()).find('.' + searchType + '-select .selectpicker').selectpicker('refresh');


                // util.callDropDownEndpoint(searchTerm, searchType, {}, $.proxy(function (response) {
                //     console.log(response);
                //     if (response) {
                //         var orgOptions = [];

                //         if (searchTerm) {
                //             var typedTerm = '<option value="' + searchTerm + '">' + searchTerm + '</option>';
                //             orgOptions.push(typedTerm);
                //         }

                //         for (var i = 0; i < response.length; i++) {
                //             var option = '<option value="' + response[i] + '">' + response[i] + '</option>';
                //             orgOptions.push(option);
                //         }

                //         $(this.getSelectors()).find('.' + searchType + '-select .selectpicker').html(orgOptions);
                //         $(this.getSelectors()).find('.' + searchType + '-select .selectpicker').selectpicker('refresh');
                //     } else {
                //         console.log('fail');
                //         // TODO - Error handling
                //     }
                // }, this));
            },

            performSearch: function (e) {
                //e.preventDefault();
                var module = this;

                this.searchCount = 1;

                if (e) {
                    $(this.getSelectors()).find('.results-container-title h3').addClass('colorful');
                }

                // TODO determine if we need any sort of loading indicator
                // $(this.getSelectors()).find('.results-loader').css('width', '0%');
                // $(this.getSelectors()).find('.resultsContainer').html(templates.searchabledatabaseloading);
                // $(this.getSelectors()).find('.sample-results').addClass('show');
                //$(this.getSelectors()).find('.results-loader').addClass('loading');

                var searchTerm = $(this.getSelectors()).find('.searchInput').val().toLowerCase();
                var OrganizationNameTerm = $(this.getSelectors()).find('select[name="organization"]').val().toLowerCase() || '';
                var SolutionCategoryTerm = $(this.getSelectors()).find('select[name="category"]').val() || '';
                var LocationTerm = $(this.getSelectors()).find('select[name="location"]').val().toLowerCase() || '';
                var SolutionTypeTerm = $(this.getSelectors()).find('select[name="type"]').val() || '';
                var pageNumber = 0;

                var tempData = this.data.filter(element => {

                    if (OrganizationNameTerm) {
                        if (!element.OrganizationName.toLowerCase().includes(OrganizationNameTerm)) {
                            return false;
                        }
                    };

                    if (SolutionCategoryTerm) {
                        if (element.SolutionCategoryTerm !== SolutionCategoryTerm) {
                            return false;
                        }
                    };

                    if (LocationTerm) {
                        if (!element.Location.toLowerCase().includes(LocationTerm)) {
                            return false;
                        }
                    };

                    if (SolutionTypeTerm) {
                        if (element.SolutionTypeTerm !== SolutionTypeTerm) {
                            return false;
                        }
                    }

                    if (searchTerm) {
                        if (!element.ProposalTitle.toLowerCase().includes(searchTerm) && 
                            !element.ExecutiveSummary.toLowerCase().includes(searchTerm) && 
                            !element.SolutionNarrative.toLowerCase().includes(searchTerm) && 
                            !element.OrganizationName.toLowerCase().includes(searchTerm)) {
                            return false;
                        }
                    };
                    return true;
                });

                if (tempData.length > 0) {
                    $(this.getSelectors()).find('.resultsContainer').html(templates.searchabledatabaselisting({
                        response: tempData,
                        config: config
                    }));
    
                    $(this.getSelectors()).find('.expand-result').on('click', function () {
                        var self = $(this),
                            searchResultContainer = self.closest('.search-result'),
                            resultId = self.attr('data-resultid');
    
                        if (searchResultContainer.hasClass('result-expanded')) {
                            module.closeDetails(resultId);
                            setTimeout(function () {
                                searchResultContainer.removeClass('result-expanded');
                            }, 400);
                        } else {
                            module.getDetails(resultId);
                            searchResultContainer.addClass('result-expanded');
                        }
                    });
                } else {
                    $(this.getSelectors()).find('.resultsContainer').html(templates.searchabledatabasenoresults());
                }

                // util.callSearchEndpoint('', {
                //     searchTerm: $(this.getSelectors()).find('.searchInput').val(),
                //     OrganizationNameTerm: $(this.getSelectors()).find('select[name="organization"]').val() || '',
                //     SolutionCategoryTerm: $(this.getSelectors()).find('select[name="category"]').val() || '',
                //     LocationTerm: $(this.getSelectors()).find('select[name="location"]').val() || '',
                //     SolutionTypeTerm: $(this.getSelectors()).find('select[name="type"]').val() || '',
                //     pageNumber: 0
                // }, $.proxy(function (response) {
                //     if (response.length !== 0) {
                //         $(this.getSelectors()).find('.resultsContainer').html(templates.searchabledatabaselisting({
                //             response: response,
                //             config: config
                //         }));
                //         if (response.length >= 20) {
                //             $(this.getSelectors()).find('.load-more-results').show();
                //         }
                //         $(this.getSelectors()).find('.expand-result').on('click', function () {
                //             var self = $(this),
                //                 searchResultContainer = self.closest('.search-result'),
                //                 resultId = self.attr('data-resultid');

                //             console.log('expand-result');

                //             if (searchResultContainer.hasClass('result-expanded')) {
                //                 module.closeDetails(resultId);
                //                 setTimeout(function () {
                //                     searchResultContainer.removeClass('result-expanded');
                //                 }, 400);
                //             } else {
                //                 module.getDetails(resultId);
                //                 searchResultContainer.addClass('result-expanded');
                //             }
                //         });
                //     } else {
                //         console.log('fail');
                //         $(this.getSelectors()).find('.resultsContainer').html(templates.searchabledatabasenoresults());
                //     }
                // }, this), $.proxy(function (progress) {
                //     //$(this.getSelectors()).find('.results-loader').css('width', progress + '%');
                // }, this));
                if (e) {
                    $('html,body').animate({
                        scrollTop: $('.results-container').offset().top - 50
                    }, 1000);
                }

                return false;
            },

            loadMore: function (e) {

                e.preventDefault();
                this.searchCount++;

                // TODO determine if we need any sort of loading indicator
                // $(this.getSelectors()).find('.results-loader').css('width', '0%');
                // $(this.getSelectors()).find('.resultsContainer').html(templates.searchabledatabaseloading);
                // $(this.getSelectors()).find('.sample-results').addClass('show');
                //$(this.getSelectors()).find('.results-loader').addClass('loading');

                // util.callSearchEndpoint('', {
                //     searchTerm: $(this.getSelectors()).find('.searchInput').val(),
                //     OrganizationNameTerm: $(this.getSelectors()).find('select[name="organization"]').val() || '',
                //     SolutionCategoryTerm: $(this.getSelectors()).find('select[name="category"]').val() || '',
                //     LocationTerm: $(this.getSelectors()).find('select[name="location"]').val() || ',',
                //     SolutionTypeTerm: $(this.getSelectors()).find('select[name="type"]').val() || '',
                //     pageNumber: this.searchCount
                // }, $.proxy(function (response) {

                //     if (response.length !== 0) {

                //         $(this.getSelectors()).find('.resultsContainer').append(templates.searchabledatabaselisting({
                //             response: response,
                //             config: config
                //         }));

                //         if (response.length < 20) {
                //             $(this.getSelectors()).find('.load-more-results').hide();
                //             $(this.getSelectors()).find('.resultsContainer').append('<span class="no-more-results">No More Results</span>');
                //         }


                //         // $(this.getSelectors()).find('.resultsContainer').html(templates.searchabledatabaselisting(response.results));
                //         // //$(this.getSelectors()).find('.results-loader').addClass('finished');
                //         // infiniteloader.cleanupInstances($(this.getSelectors()));
                //         // infiniteloader.addInstance({
                //         //     el: $(this.getSelectors()).find('.resultsContainer'),
                //         //     selector: '.row',
                //         //     remote: false,
                //         //     pageSize: 25,
                //         //     spinner: false
                //         // });
                //         // $(this.getSelectors()).on('click', '.search-result-play-video', $.proxy(this.playVideo, this));
                //         // $(this.getSelectors()).find('.video-close').on('click', $.proxy(this.stopVideo, this));
                //     } else {
                //         // TODO cleaner handling
                //         $(this.getSelectors()).find('.load-more-results').hide();
                //     }
                // }, this), $.proxy(function (progress) {
                //     //$(this.getSelectors()).find('.results-loader').css('width', progress + '%');
                // }, this));
            },

            getDetails: function (resultId) {
                var tempData = this.data.filter(element => {
                    if (element.Id === resultId) {
                        return element;
                    };
                });
                // tempdata here is array and not an object, thus the following conversion
                if (tempData.length > 0) {
                    var resultDestination = $(this.getSelectors()).find('.expanded-result[data-resultid="' + resultId + '"]');

                    resultDestination.prev('.search-result').addClass('result-expanded');
                    resultDestination.addClass('expanded').slideDown(400);

                    if (tempData[0].Website) {
                        if (tempData[0].Website.startsWith('http')) {
                        } else {
                            tempData[0].Website = 'http://' + tempData[0].Website;
                        }
                    }
                    if (tempData[0].VideoPitch) {
                        tempData[0].VideoPitch = 'https://www.youtube.com/embed/' + util.parseYoutubeId(tempData[0].VideoPitch);
                    }

                    resultDestination.html(templates.searchabledatabaseexpandedresult({
                        response: tempData[0],
                        config: config
                    }));
                };

                // util.callResultDetailEndpoint(resultId, {}, $.proxy(function (response) {

                //     if (response.length !== 0) {

                //         var resultDestination = $(this.getSelectors()).find('.expanded-result[data-resultid="' + resultId + '"]');

                //         resultDestination.prev('.search-result').addClass('result-expanded');
                //         resultDestination.addClass('expanded').slideDown(400);

                //         if (response.Website) {
                //             if (response.Website.startsWith('http')) {
                //             } else {
                //                 response.Website = 'http://' + response.Website;
                //             }
                //         }
                //         if (response.VideoPitch) {
                //             response.VideoPitch = 'https://www.youtube.com/embed/' + util.parseYoutubeId(response.VideoPitch);
                //         }

                //         resultDestination.html(templates.searchabledatabaseexpandedresult({
                //             response: response,
                //             config: config
                //         }));

                //     } else {
                //         console.log('fail');
                //         // TODO error handling for no detail
                //     }
                // }, this), $.proxy(function (progress) {
                //     $(this.getSelectors()).find('.results-loader').css('width', progress + '%');
                // }, this));
            },

            closeDetails: function (resultId) {
                var resultDestination = $(this.getSelectors()).find('.expanded-result[data-resultid="' + resultId + '"]');
                resultDestination.removeClass('expanded').slideUp(400, function () {
                    resultDestination.html('');
                });
            },

            resetSearch: function (e) {
                e.preventDefault();
                $(this.getSelectors()).find('.load-more-results').hide();
                $(this.getSelectors()).find('.searchInput').val('');
                $(this.getSelectors()).find('.select-container .selectpicker:not(.selectbox) select').selectpicker('val', '');
                $(this.getSelectors()).find('.select-container .selectpicker:not(.selectbox)').html('');
                $(this.getSelectors()).find('.select-container .selectpicker:not(.selectbox)').selectpicker('refresh');
                $(this.getSelectors()).find('.select-container .selectpicker.selectbox').each(function () {
                    $(this).val('');
                    $(this).selectpicker('refresh');
                });
                $(this.getSelectors()).find('.resultsContainer').html(templates.searchabledatabaseloading);
                $(this.getSelectors()).find('.sample-results').removeClass('show');
                $(this.getSelectors()).find('.results-loader').css('width', '0%');
                $(this.getSelectors()).find('.results-container-title').removeClass('colorful')
            }
        }
    });
});
