define('modules/process/slidecard/js/base.js',["app/module", "app/config", "jquery", "app/util", "templates", "moment", "swiper.jquery.min", "jquery.livequery"], function (module, config, $, util, templates, moment) {
    return new module({
        name: 'process.slidecard',
        selectors: '.process-slidecard-wrap',
        remoteData: [(config.urls.process)],

        config: {},

        routes: [{
            route: 'home',
            extra: true,
            delay: 2000,
            fn: 'initSwiper' // Route fallback method, will typically be handled via setInterval on onRender method
        }],

        initialize: function (data) {
            this.data = (data || this.data);
            this.$container = $(this.getSelectors());
            this.renderTemplates(data);
        },

        onRender: function () {
            util.setVisibleInterval(this.$container, $.proxy(this.initSwiper, this), 1000);  // Swiper will not properly initialize until the element is actually visible on DOM, hence this interval
        },

        methods: {

            renderTemplates: function (data) {
                this.$container.append(templates.processslidecard({
                    steps: data.sort(function (a, b) {
                        return a.id - b.id;
                    })
                }));
            },

            initSwiper: function () {
                function onSlideChangeStart() {
                    activebox.removeClass('active');
                }

                var currentDate = new moment(); // Current date, feed moment a date string for testing purposes
                var pastPoints = $.grep(this.data,function(a){
                    return (currentDate.diff(new Date(a.dates)) > 0);
                });
                var processId = (pastPoints.length > 0 ? pastPoints[pastPoints.length-1].id : 1);

                function onSlideChangeEnd() {
                    var wrap = $('.txt-wrap', processSwiper.slides[processSwiper.activeIndex]);
                    $('h3', activeboxInner).html($('h3', wrap).html());
                    $('.content-wrap', activeboxInner).css('height', $('.content-wrap', wrap).height()).html($('.content-wrap', wrap).html());
                    activebox.addClass('active');
                }

                if (this.swiperInstance) {
                    return;
                }

                var processSwiperContainer = this.$container.find('.swiper-container-process');

                $('<div/>').addClass('swiper-activebox').html('<div class="swiper-activebox-inner"><h3></h3><div class="content-wrap"></div></div>').insertAfter(processSwiperContainer);
                var activebox = this.$container.find('.swiper-activebox'),
                    activeboxInner = $('.swiper-activebox-inner', activebox);

                this.swiperInstance = new Swiper('.swiper-container-process', {
                    initialSlide: processId,
                    width: 354,
                    speed: 800,
                    slidesPerView: 1,
                    loop: false,
                    centeredSlides: false,
                    simulatesTouch: false,
                    shortSwipes: false,
                    longSwipes: false,
                    onlyExternal: true,
                    prevButton: '.process-swiper-navigate-prev',
                    nextButton: '.process-swiper-navigate-next',
                    breakpoints: {
                        1024: {
                            simulatesTouch: true,
                            shortSwipes: true,
                            longSwipes: true,
                            onlyExternal: false,
                            centeredSlides: true
                        },
                        767: {
                            width: 375,
                            slidesPerView: 1,
                            centeredSlides: true,
                            onlyExternal: false,
                            simulatesTouch: true,
                            shortSwipes: true,
                            longSwipes: true
                        }
                    }
                });

                var processSwiper = processSwiperContainer[0].swiper;
                processSwiper.on('onSlideChangeStart', onSlideChangeStart).on('onSlideChangeEnd', onSlideChangeEnd);
                onSlideChangeEnd();

                this.$container.find('.swiper-slide-inner').on('click', function () {
                    processSwiper.slideTo($(this).closest('.swiper-slide').index());
                });
            }
        }
    });
});

