define('modules/judges/basic/js/base.js',["app/module", "app/config", "jquery", "app/util", "templates", "jquery.livequery"], function (module, config, $, util, templates) {
    return new module({
        name: 'judges.basic',
        selectors: '.judgePageWrap, .judgeSection',
        remoteData: [config.urls.judges],
        routes: [{
            route: 'evaluation/judge/(.*)',
            fn: function (hash) {
                var module = this;

                module.onJudgeCloseClick();
                var parsedJudgeName = hash.split('/')[2].toLowerCase();
                var judgeSelector = '#judges .judgeBox[data-judge-slug="' + parsedJudgeName + '"]';
                setTimeout(function () {
                    $(judgeSelector).trigger('click');
                }, 1000);
            },
            extra: true,
            delay: 2000
        },
            {
                route: 'evaluation/group/(.*)',
                fn: function (hash) {

                    var groupSlugsMapping = {
                        'evaluation-panel': 'Evaluation Panel',
                        'stakeholder-panel': 'Stakeholder Panel',
                        'selection-committee': 'Selection Committee'
                    };
                    var parsedGroupName = hash.split('/')[2];
                    var groupSelector = '#judges .judgeGroup[data-judgegroup="' + groupSlugsMapping[parsedGroupName] + '"]';

                    if ($(groupSelector).length > 0) {
                        $('html, body').animate({
                            scrollTop: $(groupSelector).offset().top
                        }, 500);
                    }
                },
                extra: true,
                delay: 2000
            }],


        config: {},

        initialize: function (data) {
            this.data = (data || this.data);
            this.renderTemplates(this.data);
            this.clickHandlers(this.data);
        },

        methods: {

            renderTemplates: function (data) {
                var judgeGroups = [],
                    templateData = {
                        judgeGroups: []
                    };
                $.each(data, function (index, record) {
                    if (!judgeGroups[record.type]) {
                        judgeGroups[record.type] = {
                            name: record.type,
                            title: record.shortDescription,
                            desc: record.desc,
                            cms: record.cms,
                            records: []
                        };
                    }
                    judgeGroups[record.type].records.push(record);
                });
                for (var key in judgeGroups) {
                    if (judgeGroups.hasOwnProperty(key)) {
                        templateData.judgeGroups.push(judgeGroups[key]);
                    }
                }
                this.templateData = templateData;
                $('.judgePageWrap').append(templates.judgespage({
                    data: templateData,
                    config: this.config
                }));

                $('.judgeGridContainer').each(function () {
                    $('.judgeBox', this).eq(0).addClass('active');
                });

                // Init judges carousel in popup
                $('.judgeGridSwiper').each(function () {
                    var swiperInstance = new Swiper(this, {
                        width: 704,
                        slidesPerView: 3,
                        centeredSlides: false,
                        spaceBetween: 0,
                        loop: false,
                        simulatesTouch: false,
                        shortSwipes: false,
                        longSwipes: false,
                        onlyExternal: true,
                        prevButton: ".judge-swiper-navigate-prev",
                        nextButton: ".judge-swiper-navigate-next",
                        breakpoints: {
                            1200: {
                                width: 764,
                                slidesPerView: 3
                            },
                            1024: {
                                width: 764,
                                onlyExternal: false,
                                simulatesTouch: true,
                                shortSwipes: true,
                                longSwipes: true
                            },
                            992: {
                                width: 704
                            },
                            767: {
                                width: 375,
                                slidesPerView: 1,
                                centeredSlides: true,
                                onlyExternal: false,
                                simulatesTouch: true,
                                shortSwipes: true,
                                longSwipes: true
                            }
                        }
                    });
                });

                $(this.getSelectors()).find('.judge-long-description').each(function () {
                    if ($(this).find('.judge-quote-container').length) {
                        var foo = $(this).find('.judge-quote-container');
                        //$(this).find('p:eq(0)')
                        //$("#mydiv div:eq(0)").after
                    }
                });
            },

            clickHandlers: function () {
                $(this.getSelectors()).find('.judgeBox').on('click', $.proxy(this.onJudgeBoxClick, this));
                $(this.getSelectors()).find('.judgeDetailClose').on('click', $.proxy(this.onJudgeCloseClick, this));

            },

            onJudgeCloseClick: function () {

                var moduleGetSelectors = $(this.getSelectors());

                $(this.getSelectors()).find('#judgesDetail').removeClass('judgeDetailOpen').delay(1000).queue(function (next) {
                    $(this).hide();
                    moduleGetSelectors.find('#judgesDetail').hide();
                    moduleGetSelectors.find('.judgeDetailContainer').removeClass('active');
                    moduleGetSelectors.find('.judgeInfoGroup').removeClass('active');
                    moduleGetSelectors.find('.judgeInfoContainer').removeClass('active');
                    moduleGetSelectors.find('.judgesBasicNav .currentCount').html('');
                    $('body').removeClass('locked');
                    $('.navbarWrap').removeClass('slideUpOut');
                    next();
                });
            },

            onJudgeBoxClick: function (e) {
                var jGroup = $(e.delegateTarget).attr('data-judgegroup'),
                    jId = $(e.delegateTarget).attr('data-judge');

                $('body').addClass('locked');
                $('.navbarWrap').addClass('slideUpOut');

                $(this.getSelectors()).find('#judgesDetail').show().delay(50).queue(function (next) {
                    $(this).addClass('judgeDetailOpen');
                    next();
                });

                $(this.getSelectors()).find('#judgesDetail').scrollTop(0);

                $(this.getSelectors()).find('.judgeDetailContainer[data-judgegroup="' + jGroup + '"]').addClass('active');
                $(this.getSelectors()).find('.judgeInfoGroup[data-judgegroup="' + jGroup + '"]').addClass('active');
                $(this.getSelectors()).find('.judgeInfoContainer[data-judge="' + jId + '"]').addClass('active').siblings('.active').removeClass('active');

                var activeJudgeId = jId;
                $(this.getSelectors()).find('.judgeDetailCounter .currentCount').html(activeJudgeId);
                $(this.getSelectors()).find('.judgeGroup').show();

                $(this.getSelectors()).find('.judgeDetailContainer[data-judgegroup="' + jGroup + '"]').find('.judgeGridSwiper').eq(0).each(function () {
                    $(this).find('.judgeBox[data-judge=' + activeJudgeId + ']').closest('.swiper-slide').addClass('swiper-slide-hidden').siblings('.swiper-slide').removeClass('swiper-slide-hidden');
                    this.swiper.update();
                    this.swiper.slideTo(0);
                    if (this.swiper.slides.length === 1) {
                        $(this).closest('.judgeGroup').hide();
                    }
                });

                $('.judgeGridContainer .judgeBox.active').removeClass('active');
                $(e.delegateTarget).addClass('active');

                this.judgesBasicGroupNav();
            },

            judgesBasicGroupNav: function () {
                var moduleGetSelectors = $(this.getSelectors()).find('.judgeInfoGroup.active'),
                    that = $(this.getSelectors()),
                    activeIndex,
                    lastIndex,
                    nextIndex,
                    prevIndex;

                // handle jude nav in it's own module
                $(this.getSelectors()).find('.judgeInfoGroup.active .judgeDetailNav.prev, .judgeInfoGroup.active .judgeDetailNav.next').unbind('click').on('click', $.proxy(function (e) {
                    activeIndex = +(moduleGetSelectors.find('.judgeDetailCounter .currentCount').html());
                    nextIndex = activeIndex + 1;
                    prevIndex = activeIndex - 1;
                    lastIndex = +(moduleGetSelectors.find('.judgeDetailCounter .totalCount').html());

                    //next at end
                    if ($(e.currentTarget).hasClass('next') && (activeIndex == lastIndex)) {

                        moduleGetSelectors.find('.judgeInfoContainer.active').removeClass('active');
                        moduleGetSelectors.find('.judgeInfoContainer').eq(0).addClass('active');
                        moduleGetSelectors.find('.judgeDetailCounter .currentCount').html('1');
                        that.find('.judgeGridContainer .judgeBox.active').removeClass('active');
                        that.find('.judgeGridContainer .judgeBox:first-child').addClass('active');
                    }
                    // next
                    else if ($(e.currentTarget).hasClass('next')) {
                        moduleGetSelectors.find('.judgeInfoContainer.active').removeClass('active').next('.judgeInfoContainer').addClass('active');
                        moduleGetSelectors.find('.judgeDetailCounter .currentCount').html(nextIndex);
                        that.find('.judgeGridContainer .judgeBox.active').removeClass('active').next().addClass('active');
                    }
                    // prev at end
                    else if ($(e.currentTarget).hasClass('prev') && activeIndex == 1) {
                        moduleGetSelectors.find('.judgeInfoContainer.active').removeClass('active');
                        moduleGetSelectors.find('.judgeInfoContainer').eq(lastIndex - 1).addClass('active');
                        moduleGetSelectors.find('.judgeDetailCounter .currentCount').html(lastIndex);
                        that.find('.judgeGridContainer .judgeBox.active').removeClass('active');
                        that.find('.judgeGridContainer .judgeBox:last-child').addClass('active');
                    } else if ($(e.currentTarget).hasClass('prev')) {
                        moduleGetSelectors.find('.judgeInfoContainer.active').removeClass('active').prev('.judgeInfoContainer').addClass('active');
                        moduleGetSelectors.find('.judgeDetailCounter .currentCount').html(prevIndex);
                        that.find('.judgeGridContainer .judgeBox.active').removeClass('active').prev().addClass('active');
                    }

                    $('#judgesDetail').scrollTop(0);
                }));
            }

        }
    });
});

