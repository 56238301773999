define('modules/animations/scroll/js/base.js',["app/config","jquery","app/util","bootstrap/collapse","jquery.livequery"], function(config,$,util) {
    return {
        name: 'scroll',
        selectors: false,
        remoteData: [],

        initialize: function() {

            // Add scroll link handlers
            $('.downArrow').click(function(){
                $('html, body').animate({
                    scrollTop: $(window).scrollTop()+($(window).height()*0.9)
                }, 500);
                return false;
            });

            // Any elements with "data-scrollto" attribute with a valid selector, when clicked will scroll to that element
            $('body').livequery('*[data-scrollto]',function() {
                $(this).click(function (e) {
                    e.preventDefault();
                    if ($('body').hasClass('spappcard')) {
                        $('body').trigger('snaptocard', $($(this).attr('data-scrollto')));
                    } else {
                        if ($(this).attr('data-scrollto').length > 0) {
                            util.scrollElToTop($($(this).attr('data-scrollto')), ($(this).attr('data-scrolloffset') || 0));
                        }
                    }
                    $('body').trigger('navbartoggle',false);
                });
            });


            // TODO write better config for handling ie
            // Any elements with "data-scrollto" attribute with a valid selector, when clicked will scroll to that element
            $('body').livequery('*[data-scrolltoie]',function() {
                $(this).click(function (e) {
                    e.preventDefault();
                    if ($(this).attr('data-scrolltoie').length > 0) {
                        var scrollTarget = $(this).attr('data-scrolltoie');
                        $('html,body').animate({
                            scrollTop: $(scrollTarget).offset().top
                        }, 1000);
                    }
                    $('body').trigger('navbartoggle',false);
                });
            });

            // Section smoothed linking
            $('.smoothBtn').click(function(e){
                e.preventDefault();
                var linkTop = $(this).attr('href');
                if (linkTop) {
                    $('body,html').animate({scrollTop: $(linkTop).offset().top});
                }
            });

            // Stop overscroll on all classed elements
            $('body').livequery('.stopOver',function(){
                util.preventOverscroll($(this));
            },function(){
                util.preventOverscroll($(this),false);
            });


            // universalizing the home learn more btn
            // the nav bar should act independently of this function

            $('body').livequery('.js-learn-more-btn',function() {
                $(this).click(function (e) {
                    e.preventDefault();
                    if ($(this).attr('data-learnmore-scrollto').length > 0) {
                        var scrollTarget = $(this).attr('data-learnmore-scrollto');
                        $('html,body').animate({
                            scrollTop: $(scrollTarget).offset().top
                        }, 1000, function() {
                            $('.home-side-nav__list__item').removeClass('toggle-out toggle-in');
                        });
                    }
                });
            });

            // Set delegated handling for disabling body scrolling on iOS touch devices
            /*if($('html').hasClass('touch') && util.device.ios()) {

                var selScrollable = els;
                $(document).on('touchmove',function(e){
                    e.preventDefault();
                });
                $('body').on('touchstart','lockedchild', function(e) {
                    if (e.currentTarget.scrollTop === 0) {
                        e.currentTarget.scrollTop = 1;
                    } else if (e.currentTarget.scrollHeight === e.currentTarget.scrollTop + e.currentTarget.offsetHeight) {
                        e.currentTarget.scrollTop -= 1;
                    }
                });
                $('body').on('touchmove', selScrollable, function(e) {
                    e.stopPropagation();
                });
            }*/
        }
    }
});
